@import "~styles/_mixins";

#view-event {
  margin-top: 44px;
  section {
    margin: 28px 0;
    .section-label {
      @include theme-aware('color', 'faded');
      font-weight: bold;
      padding-bottom: 8px;
    }
    .date-time-container {
      max-width: $maxWidth;
      display: flex;
      justify-content: space-between;
      input {
        width: 39%;
      }
    }
    .timezone-container {
      max-width: $maxWidth;
      width: 100%;
      margin-top: 20px;
      .timezone {
        width: 100%;
      }
    }
    #description {
      line-height: 1.35em;
      max-width: $maxWidth;
    }
    a {
      color: $primary;
    }
  }
}