@import "_color-palette";

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
 @mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
      .theme-#{$theme-name} & {
          #{$key}: map-get(map-get($themes, $theme-name), $color)
      }
  }
}


@mixin dashed-borders {
  @include theme-aware('background', 'element-background');
  @include theme-aware('color', 'text-color');
  @include theme-aware('border-color', 'faded');
  border-width: 1px;
  border-style: dashed;
  border-radius: 4px;
  &:hover, &:focus {
    border-color: $primary;
  }
  &:disabled {
    @include theme-aware('border-color', 'faded');
  }
  &.error {
    border-color: $error;
  }
}