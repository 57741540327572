@import 'styles/_mixins';

#create-event-page {
  width: 100%;
  display: flex;
  h1 {
    margin-top: 32px;
  }
  #create-event-form-container {
    min-width: 60%;
  }
  #create-event-form {
    margin-top: 20px;
    .date-time-container {
      max-width: $maxWidth;
      display: flex;
      justify-content: space-between;
      input {
        width: 39%;
      }
    }
    .timezone-container {
      max-width: $maxWidth;
      width: 100%;
      margin-top: 20px;
      .timezone {
        width: 100%;
      }
    }
  
    button.submit {
      margin-top: 32px;
    }
  }
}

@media(max-width: 768px) {
  #create-event-page {
    flex-direction: column-reverse;
  }
}