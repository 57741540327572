@import "styles/_mixins";

#success-container {
  margin-top: 8px;
  min-width: 40%;
  #success {
    @include theme-aware('background', 'darker-background');
    padding: 32px;
    border-radius: 8px;
    .copy-to-clipboard {
      @include dashed-borders;
      position: relative;
      overflow-y: auto;
      border-color: $primary !important;
      font-size: 0.85em;
      padding: 1em;
      margin-top: 44px;
      &:hover {
        cursor: pointer;
      }
      .indicator { 
        position: absolute;
        top: 8px;
        right: 8px;
        @include theme-aware('color', 'faded');
        .copied {
          line-height: 20px;
        }
      }
      svg {
        width: 18px;
        height: 20px;
        text {
          @include theme-aware('fill', 'faded');
        }
      }
    }
  }
}

@media(max-width: 768px) {
  #success {
    padding: 16px;
  }
}