@import "_mixins";

button {
  display: block;
  background: $primary;
  padding: 24px;
  text-transform: uppercase;
  width: 100%;
  max-width: 344px;
  border: none;
  border-radius: 12px 16px;

  &.option-button {
    @include dashed-borders;
    margin: 12px 0;
    text-transform: capitalize;
    padding: 4px;
    text-align: left;
    width: auto;
    &:hover {
      border-color: $primary;
    }
  }
}