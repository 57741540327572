$primary: #00D4F2;
$error: #b94444;

$maxWidth: 424px;

$themes: (
  light: (
    background: #FAFAFA,
    element-background: #F8F8F8,
    darker-background: #f3f3f3,
    text-color: #1C1C24,
    faded: #696969,
  ),
  dark: (
    background: #1C1C24,
    element-background: #202029,
    darker-background: #131317,
    text-color: #FAFAFA,
    faded: #696969
  )
);