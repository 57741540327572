@import 'styles/_mixins';

textarea, input, select {
  @include dashed-borders;
  padding: 1em;
  font-family: inherit;
  outline: none;
}

input {
  max-width: calc(#{$maxWidth} - 2em);
  width: calc(100% - 2em);
}

textarea {
  width: calc(100% - 2em);
  max-width: calc(#{$maxWidth} - 2em);
  min-height: 116px;
}

select {
  option {
    text-transform: capitalize;
  }
  min-width: 100px;
  max-width: $maxWidth;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
}

fieldset {
  padding: 12px 0;
  label {
    font-size: 0.85em;
    font-weight: 700;
    display: block;
    padding-bottom: 16px; 
  }
}


form {
  .optional {
    text-transform: uppercase;
    margin-left: 8px;
    font-weight: 900;
    font-size: 0.8em;
    @include theme-aware('color', 'faded');
  }
}