@import "~styles/_mixins";

svg {
  width: 20px;
  height: 28px;
  vertical-align: middle;
  margin-left: 28px;
  path {
    @include theme-aware('fill', 'faded');
  }
  // &.dark-mode path {
  // }
}