@import '_mixins';
@import 'reset';

// common components
@import 'button';
@import 'form';

// layout
@import 'header';

// Get the color-background property from the active theme map and apply its value to the "background" css property
body {
  font-family: 'Manrope', sans-serif;
  padding: 2em;
  @include theme-aware('background', 'background');
  @include theme-aware('color', 'text-color');
}

#app-container {
  min-height: calc(100vh - 64px);
  max-width: 1200px;
  margin: 0 auto;
  main {
    margin-top: 20px;
  }
}
