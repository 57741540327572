
@import 'styles/_mixins';

.timezone-selector {
  &__control {
    @include dashed-borders;
    @include theme-aware('background-color', 'element-background');
    border-style: dashed !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    &:hover {
      border-color: $primary !important;
    }
  }

  &__menu {
    @include theme-aware('background-color', 'element-background');
  }

  &__option--is-focused, &__option--is-selected {
    color: #1C1C24 !important;
    background-color: $primary !important;
  }

  &__input {
    @include theme-aware('color', 'text-color');
  }
  &__single-value {
    @include theme-aware('color', 'text-color');
    font-size: 0.8em !important;
  }
}